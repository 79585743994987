/* eslint-disable space-unary-ops */
import { activeArray } from '@/utils/localStorage.js'
import { deepClone } from '@/utils/deepClone.js'

// 基本字段
let tableData = [
  {
    label: '合约ID',
    prop: 'contractID',
    show: true,
    enableSlot: true,
    minWidth: 160
  },
  {
    label: '合约名称',
    prop: 'remark',
    show: true,
    enableSlot: true,
    align: 'left',
    minWidth: 160
  },
  {
    label: '分片名',
    prop: 'shard',
    show: true,
    align: 'center',
    minWidth: 100
  },
  {
    label: '执行次数',
    prop: 'cycle',
    show: true,
    enableSlot: true,
    align: 'center',
    minWidth: 100
  },
  {
    label: '已执行',
    prop: 'execute',
    show: true,
    enableSlot: true,
    align: 'center',
    minWidth: 100
  },
  {
    label: '开始条件',
    prop: 'startTriggerID',
    show: true,
    enableSlot: true,
    align: 'center',
    minWidth: 100
  },
  {
    label: '执行间隔',
    prop: 'cron',
    show: true,
    enableSlot: true,
    align: 'center',
    minWidth: 100
  },
  {
    label: '停止条件',
    prop: 'stopTriggerID',
    show: true,
    enableSlot: true,
    align: 'center',
    minWidth: 100
  },
  {
    label: '执行状态',
    prop: 'status',
    show: true,
    enableSlot: true,
    align: 'center',
    minWidth: 100
  },
  {
    label: '创建时间',
    prop: 'createTime',
    align: 'center',
    show: true,
    enableSlot: true,
    width: 120
  },
  {
    label: '操作',
    prop: 'operators',
    width: 180,
    align: 'right',
    fixed: 'right',
    children: {
      detail: {
        label: '查看',
        icon: 'el-icon-view',
        clickEvent: (thisVue, row) => {
          thisVue.handleDetail(row.id)
        }
      },
      pause: {
        label: '暂停',
        icon: 'el-icon-video-pause',
        color: '#E6A23C',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          if (row.status === 1) {
            return activeArray('/api/contract/task-pause-(\\d+)')
          }
        },
        clickEvent: (thisVue, row) => {
          thisVue.handleStop(row.id)
        }
      },
      resume: {
        label: '重启',
        icon: 'el-icon-video-play',
        color: '#67C23A',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          if (row.status === 3) {
            return activeArray('/api/contract/task-resume-(\\d+)')
          }
        },
        clickEvent: (thisVue, row) => {
          thisVue.handleStart(row.id)
        }
      }
    }
  }
  // {
  //   label: '操作',
  //   prop: 'id',
  //   align: 'center',
  //   show: true,
  //   enableSlot: true,
  //   width: 180
  // }
]

// 新增表单配置
let addForm = [
  {
    showbutton: true,
    slotArr: ['startTime'],
    items: {
      shard: {
        label: '分片名',
        type: 'select',
        clearable: true,
        placeholder: '请选择',
        rules: [
          { required: true, message: '请选择分片名', trigger: 'change' }
        ],
        children: []
      },
      contractID: {
        label: '合约ID',
        type: 'text',
        clearable: true,
        placeholder: '请输入',
        rules: [
          { required: true, message: '请输入合约ID', trigger: 'change' }
        ]
      },
      func: {
        label: '函数名',
        type: 'text',
        clearable: true,
        placeholder: '请输入',
        rules: [
          { required: true, message: '请输入函数名', trigger: 'change' }
        ]
      },
      args: {
        label: '外部参数',
        type: 'text',
        clearable: true,
        placeholder: '请输入',
        iconBtn: 'el-icon-circle-plus-outline',
        buttonEvent: (flag, form, group, data, thisVue) => {
          // console.log('按钮事件')
          // console.log({ thisVue: thisVue })
          var arr = []
          var items = addForm[0].items
          for (var key in items) {
            arr.push(key)
          }
          // console.log(arr)
          var insert = arr.findIndex((item) => item === 'startTime')
          // var argsKey = 'args' + JSON.stringify(insert - 2)
          // 判断前一位字段来自定义组装字段名
          var str = arr[insert - 1]
          if (str === 'args') {
            var args10 = 'args10'
            arr.splice(insert, 0, args10)
            // console.log('数字1:', args1)
            console.log('新数组:', arr)
            items[args10] = {
              label: '外部参数',
              type: 'text',
              clearable: true,
              placeholder: '请输入',
              iconBtn: 'el-icon-remove-outline',
              buttonEvent: (flag, form, group, data, thisVue) => {
                console.log('删减事件:', args10)
                console.log('group:', group.items)
                // 删除表单配置项
                thisVue.$delete(thisVue.addForm[0].items, args10)
                // 删除表单值
                thisVue.$delete(thisVue.dialogValue, args10)
                // var _arr = []
                // var _items = thisVue.addForm[0].items
                // for (var key in _items) {
                //   _arr.push(key)
                // }
                // console.log('删除后数组:', _arr)
                // var start = _arr.findIndex((item) => item === 'args')
                // var end = _arr.findIndex((item) => item === 'startTime')
                // var interval = end - start - 1
                // console.log('interval:', interval)
                // if (interval !== 0) {
                //   for (var k = 2; k <= interval; k++) {
                //     let label = '外部参数' + JSON.stringify(k)
                //     _items[_arr[start - 1 + k]].label = label
                //   }
                // }
                // thisVue.addForm[0].items = deepClone(_items)
              }
            }
          } else {
            var keyNum = JSON.parse(str.substr(str.length - 2, str.length)) + 1
            var _argsKey = 'args' + keyNum
            arr.splice(insert, 0, _argsKey)
            // console.log('数字2:', keyNum)
            // var addLabel = '外部参数' + JSON.stringify(keyNum - 8)

            items[_argsKey] = {
              label: '外部参数',
              type: 'text',
              clearable: true,
              placeholder: '请输入',
              iconBtn: 'el-icon-remove-outline',
              buttonEvent: (flag, form, group, data, thisVue) => {
                console.log('删减事件:', _argsKey)
                // console.log('表单值:', thisVue.dialogValue)
                // 删除表单配置项
                thisVue.$delete(thisVue.addForm[0].items, _argsKey)
                // 删除表单值
                thisVue.$delete(thisVue.dialogValue, _argsKey)
                // var _arr = []
                // var _items = thisVue.addForm[0].items
                // for (var key in _items) {
                //   _arr.push(key)
                // }
                // console.log('删除后数组:', _arr)
                // var start = _arr.findIndex((item) => item === 'args')
                // var end = _arr.findIndex((item) => item === 'startTime')
                // var interval = end - start - 1
                // console.log('interval:', interval)
                // if (interval !== 0) {
                //   for (var k = 2; k <= interval; k++) {
                //     let label = '外部参数' + JSON.stringify(k)
                //     _items[_arr[start - 1 + k]].label = label
                //   }
                // }
                // thisVue.addForm[0].items = deepClone(_items)
                // console.log('新数据:', thisVue.addForm[0].items)
              }
            }
          }
          // console.log({ insert: insert })
          var newData = {}
          for (var i in arr) {
            var itemKey = arr[i]
            newData[itemKey] = items[itemKey]
          }

          thisVue.addForm[0].items = deepClone(newData)
          // console.log('测试:', addForm[0].items)
        }
      },
      startTime: {
        label: '开始时间',
        type: 'slot',
        name: 'startTime',
        refName: 'aa',
        rules: [
          { required: true, message: '请选择开始时间', trigger: 'change' },
          {
            validator: function (rule, value, callback) {
              return callback()
            }
          }
        ]
      },
      startTriggerID: {
        label: '开始条件',
        type: 'select',
        clearable: true,
        children: [],
        rules: [
          { type: 'number', message: '请选择执行条件', trigger: 'change' }
        ],
        changeEvent: (flag, form, group, data, thisVue) => {
          // console.log(flag)
          // 判断是否有选中值
          if (flag && flag !== 1) {
            var arr = []
            var items = addForm[0].items
            for (var key in items) {
              arr.push(key)
            }
            // console.log(arr)
            // 找到执行条件下一个的下标
            var insert = arr.findIndex((item) => item === 'startTriggerID') + 1
            // 获取列表参数
            let options = {
              triggerID: flag
            }
            thisVue.$api.middleWare.triggerArgs(options).then(res => {
              if (res.data.length !== 0) {
                // console.log({ res: res.data })
                thisVue.startList = res.data
                for (var j = 0; j < res.data.length; j++) {
                  var k = 'start' + j
                  // console.log('/' + res.data[j].argReg + '/')
                  items[k] = {
                    label: res.data[j].label,
                    type: 'text',
                    clearable: true,
                    placeholder: '请输入',
                    rules: [
                      { required: true, message: '该输入框不能为空', trigger: 'change' },
                      { required: true, pattern: new RegExp(res.data[j].argReg), message: res.data[j].argRegAlert, trigger: 'change' }
                    ]
                  }
                  arr.splice(j + insert, 0, k)
                }
                var newData = {}
                for (var i in arr) {
                  var itemKey = arr[i]
                  newData[itemKey] = items[itemKey]
                  // addForm[0].items = newData
                  // console.log('测试:', addForm[0].items)
                }
                // 删除原有的配置项
                for (let key in newData) {
                  // console.log(key)
                  for (var z = res.data.length; z < 10; z++) {
                    var isKey = 'start' + z
                    if (key === isKey) {
                      // console.log(key, isKey)
                      thisVue.$delete(newData, isKey)
                    }
                  }
                }
                // 删除原有的值start0开始
                for (let _k = 0; _k < 10; _k++) {
                  let formKey = 'start' + _k
                  if (thisVue.dialogValue[formKey]) {
                  // console.log('formKey:', formKey)
                    thisVue.dialogValue[formKey] = ''
                  }
                  // thisVue.$delete(thisVue.dialogValue, formKey)
                }
                // console.log('原有表单值:', thisVue.$refs.addForm.$parent.$parent.cancel())
                thisVue.addForm[0].items = deepClone(newData)
                // console.log(thisVue.addForm[0].items)
              }
            })
          } else {
            // 恢复原始表单
            var _obj = addForm[0].items
            for (let key in _obj) {
              // console.log(key)
              for (var z = 0; z < 10; z++) {
                var isKey = 'start' + z
                if (key === isKey) {
                  // console.log(key, isKey)
                  thisVue.$delete(_obj, isKey)
                }
              }
            }
            addForm[0].items = _obj
            thisVue.startList = []
            // console.log({ '无条件': _obj })
          }
        }
      },
      cron: {
        label: '执行间隔/(秒)',
        type: 'number',
        clearable: true,
        placeholder: '请输入',
        min: 1,
        max: 86400,
        rules: [
          { required: true, message: '请输入执行间隔', trigger: 'change' },
          {
            validator: function (rule, value, callback) {
              var re = /^\+?[1-9][0-9]*$/
              if (value && value > 0 && value <= 86400 && re.test(value)) {
                return callback()
              }
              return callback(new Error('只能输入1~86400的整数'))
            }
          }
        ]
      },
      cycle: {
        label: '执行次数/(次)',
        type: 'number',
        clearable: true,
        placeholder: '请输入',
        min: 1,
        max: 999,
        rules: [
          { required: true, message: '请输入执行次数', trigger: 'change' },
          {
            validator: function (rule, value, callback) {
              var re = /^\+?[1-9][0-9]*$/
              if (value && value > 0 && value <= 999 && re.test(value)) {
                return callback()
              }
              return callback(new Error('只能输入1~999的整数'))
            }
          }
        ]
      },
      stopTriggerID: {
        label: '停止条件',
        type: 'select',
        clearable: true,
        children: [],
        rules: [
          { type: 'number', message: '请选择停止条件', trigger: 'change' }
        ],
        changeEvent: (flag, form, group, data, thisVue) => {
          // console.log(flag)
          // 判断是否有选中值
          if (flag && flag !== 1) {
            // 获取列表参数
            let options = {
              triggerID: flag
            }
            thisVue.$api.middleWare.triggerArgs(options).then(res => {
              if (res.data.length !== 0) {
                thisVue.stopList = res.data
                let items = thisVue.addForm[0].items
                // console.log({ items: items })
                for (var i = 0; i < res.data.length; i++) {
                  items['tt' + i] = {
                    label: res.data[i].label,
                    type: 'text',
                    clearable: true,
                    placeholder: '请输入',
                    rules: [
                      { required: true, message: '该输入框不能为空', trigger: 'change' },
                      { pattern: res.data[i].argReg, message: res.data[i].argRegAlert, trigger: 'change' }
                    ]
                  }
                }
                // 删除原有的项
                var _items = deepClone(items)
                for (let key in _items) {
                  // console.log(key)
                  for (var z = res.data.length; z < 10; z++) {
                    var isKey = 'tt' + z
                    if (key === isKey) {
                      // console.log(key, isKey)
                      thisVue.$delete(_items, isKey)
                    }
                  }
                }
                // 删除原有的值tt0开始
                for (let _k = 0; _k < 10; _k++) {
                  let formKey = 'tt' + _k
                  if (thisVue.dialogValue[formKey]) {
                    thisVue.dialogValue[formKey] = ''
                  }
                }
                // thisVue.cancel()
                thisVue.addForm[0].items = deepClone(_items)
                // console.log(thisVue.addForm[0].items)
              }
            })
          } else {
            // 恢复原始表单
            var _obj = addForm[0].items
            for (let key in _obj) {
              // console.log(key)
              for (var z = 0; z < 10; z++) {
                var isKey = 'tt' + z
                if (key === isKey) {
                  // console.log(key, isKey)
                  thisVue.$delete(_obj, isKey)
                }
              }
            }
            thisVue.stopList = []
            addForm[0].items = _obj
            // console.log({ '无条件': _obj })
          }
        }
      }
    }
  }
]

// 表头图标配置
let headData = {
  title: '合约任务列表',
  items: [
    /* 新增按钮 */
    {
      style: 'button',
      label: '新增',
      icon: 'el-icon-plus',
      size: 'medium',
      type: 'primary',
      // 控制按钮显示隐藏
      showFilter: (item, thisVue) => {
        return activeArray('/api/contract/task-add')
      },
      clickEvent: (thisVue) => {
        // console.log('点击：', thisVue)
        // thisVue.addForm = thisVue.tableObj.addForm
        // 恢复原始表单
        var oldData = addForm[0].items
        var _items = {}
        _items['shard'] = oldData['shard']
        _items['contractID'] = oldData['contractID']
        _items['func'] = oldData['func']
        _items['args'] = oldData['args']
        _items['startTime'] = oldData['startTime']
        _items['startTriggerID'] = oldData['startTriggerID']
        _items['cron'] = oldData['cron']
        _items['cycle'] = oldData['cycle']
        _items['stopTriggerID'] = oldData['stopTriggerID']
        addForm[0].items = _items
        thisVue.dialogValue = {
          beginTime: '',
          startTriggerID: 1,
          stopTriggerID: 1
        }
        // 清空原先的选项
        thisVue.startList = []
        thisVue.stopList = []
        thisVue.dialogTitle = '新增合约监控'
        thisVue.$set(thisVue, 'dialogShow', true)
      }
    },
    {
      style: 'button',
      label: '部署',
      icon: 'el-icon-upload',
      size: 'medium',
      type: 'primary',
      // 控制按钮显示隐藏
      showFilter: (item, thisVue) => {
        return activeArray('/api/contract/deploy')
      },
      clickEvent: (thisVue) => {
        // console.log('点击：', thisVue)
        thisVue.$router.push('contractTask/deploy')
      }
    }
  ]
}

let tableObj = {
  'tableData': tableData,
  'headData': headData,
  'addForm': addForm
}

export default tableObj
