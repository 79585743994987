<template>
  <div class="ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form" @search="search" @select="select"></search-form>
    <!-- 任务列表 -->
    <table-list :datas="searchVal"></table-list>
  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import TableList from './component/TableList.vue'
import { getShardName, setShardName } from '@/utils/localStorage.js'
export default {
  components: {
    SearchForm,
    TableList
  },
  data() {
    return {
      // 表单
      form: {
        shard: null, // 分片名（默认全部）
        status: null, // 执行状态（默认全部）
        contractID: '' // 合约ID
      },
      // 搜索控制
      searchVal: {
        shard: null, // 分片名（默认全部）
        status: null, // 执行状态（默认全部）
        contractID: '' // 合约ID
      }
    }
  },
  created() {
    // console.log('分片名:', getShardName())
    this.form.shard = getShardName()
    this.searchVal.shard = getShardName()
  },
  mounted() {
    // 新手指引
    this.$nextTick(() => {
      this.$guide.myIntroJs(this.$route.name)
    })
  },
  methods: {
    // 存储分片名
    select(value) {
      setShardName(value)
    },
    // 搜索
    search(form) {
      console.log('搜索：', form)
      // 重置合约ID
      this.form.contractID = form.contractID
      // 按条件搜索
      this.searchVal = form
    }
  }
}
</script>
