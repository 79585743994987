<template>
  <div>
    <table-list :headData="headData"
                :columns="columns"
                :data="list"
                :tableOption="{size: 'mini'}"
                :loading="loading"
                fit
                stripe
                :pageData="pageData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange">
      <!-- 合约ID -->
      <template #contractID="{row}">
        <div class="div-row">
          <popover :width="500"
                   :shard="row.shardName"
                   :value="row.contractID"
                   :isSubStr="false"
                   :divClass="'shenglue-1'"></popover>
          <i class="el-icon-document-copy" style="margin-left:10px"
             @click="copy($event, row.contractID)"></i>
        </div>
      </template>
      <!-- 合约名称 -->
      <template #remark="{row}">
        <div class="div-row">
          <popover v-if="row.remark" :width="500"
                   :value="row.remark"
                   :showDict="false"
                   :isSubStr="false"
                   :divClass="'shenglue-1'"></popover>
          <span v-else>无</span>
        </div>
      </template>
      <!-- 创建时间 -->
      <template #createTime="{row}">
        {{ tf(row.createTime, 'YYYY-MM-DD HH:mm:ss') }}
      </template>

      <!-- 执行状态 -->
      <template #status="{row}">
        <span v-if="row.status === 0">未执行</span>
        <span v-else-if="row.status === 1">执行中</span>
        <span v-else-if="row.status === 2">已结束</span>
        <span v-else-if="row.status === 3">已暂停</span>
        <span v-else-if="row.status === 4">正在暂停</span>
        <span v-else-if="row.status === 5">重启中</span>
      </template>

      <!-- 执行条件 -->
      <template #startTriggerID="{row}">
        <span v-if="row.startTriggerID">{{getTriggerTypeName(row.startTriggerID)}}</span>
        <span v-else>无</span>
      </template>

      <!-- 停止条件 -->
      <template #stopTriggerID="{row}">
        <span v-if="row.stopTriggerID">{{getTriggerTypeName(row.stopTriggerID)}}</span>
        <span v-else>无</span>
      </template>

      <!-- 执行次数 -->
      <template #cycle="{row}">
        <span v-if="row.cycle || row.cycle === 0">{{row.cycle}}次</span>
        <span v-else>0次</span>
      </template>

      <!-- 已执行次数 -->
      <template #execute="{row}">
        <span v-if="row.execute || row.execute === 0">{{row.execute}}次</span>
        <span v-else>0次</span>
      </template>

       <!-- 执行间隔 -->
      <template #cron="{row}">
        <span v-if="row.cron">{{row.cron}}秒</span>
        <span v-else>无</span>
      </template>

      <!-- 查看按钮 -->
      <!-- <template #id="{row}">
        <el-button class="table-el-btn"
                   v-if="row.status === 1||0 && getActiveArray('/api/contract/task-pause-(\\d+)')"
                   type="text"
                   icon="el-icon-video-pause"
                   @click="handleStop(row.id)"> 终止</el-button>
        <el-button class="table-el-btn"
                   v-if="row.status === 3 && getActiveArray('/api/contract/task-resume-(\\d+)')"
                   type="text"
                   icon="el-icon-video-play"
                   @click="handleStart(row.id)"> 重启</el-button>
        <el-button class="table-el-btn"
                   type="text"
                   icon="ym-icon-liulan"
                   @click="handleDetail(row.id)"> 查看</el-button>
      </template> -->

    </table-list>

    <!-- 新增表单 -->
    <el-dialog class="tableList-addForm" :close-on-click-modal="false"
               width="700px"
               :title="dialogTitle"
               :visible.sync="dialogShow"
               @close="cancel">

      <ymForm label-position="right"
              label-width="120px"
              ref="addForm"
              refCode="addForm"
              :formValue="dialogValue"
              :formData="addForm"
              @onSubmit="onSubmit">
        <template #startTime>
          <el-date-picker @change="handleTime"
                          v-model="dialogValue.beginTime"
                          :picker-options="pickerOptions"
                          format="yyyy-MM-dd HH:mm:ss"
                          type="datetime"
                          placeholder="请选择" />
        </template>
      </ymForm>
      <div class="tableList-form-buttonBox">
        <el-button size="medium"
                   @click="submitForm('addForm')"
                   type="primary"
                   v-loading="isSubmitting">提交 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import TableList from '@/components/TableList'
import Popover from '@/components/Popover'
import ymForm from '@/components/YmForm'
import timeFormat from '@/utils/timeFormat.js'
import tableObj from '../component/tableData.js'
// import {
//   activeArray
// } from '@/utils/localStorage.js'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    beginTime() {
      return this.dialogValue.beginTime
    }
  },
  watch: {
    datas: {
      handler() {
        // console.log('监听:', this.datas)
        this.pageData.currentPage = 1
        this.getData(1)
        // this.refresh(1)
      },
      deep: false,
      immediate: true
    },
    beginTime: function (newVal) {
      // console.log(newVal)
      let selectDate = this.tf(newVal, 'YYYY-MM-DD')
      let nowDate = this.tf(new Date(), 'YYYY-MM-DD')
      // console.log({ selectDate: selectDate })
      // console.log({ nowDate: nowDate })
      if (nowDate !== selectDate || nowDate < selectDate) {
        this.pickerOptions.selectableRange = '00:00:00 - 23:59:00'
      } else {
        this.pickerOptions.selectableRange =
          this.tf(
            new Date(new Date().setMinutes(new Date().getMinutes())),
            'HH:mm:ss'
          ) + ' - 23:59:59'
        this.dialogValue.beginTime =
          nowDate +
          this.tf(
            new Date(new Date().setMinutes(new Date().getMinutes())),
            ' HH:mm:ss'
          )
        // console.log({ setTime: nowDate + this.tf(new Date(new Date().setMinutes(new Date().getMinutes())), ' HH:mm:ss') })
      }
    },
    deep: false,
    immediate: true
  },
  components: {
    TableList,
    ymForm,
    Popover
  },
  data() {
    return {
      loading: false,
      pickerOptions: {
        disabledDate: (time) => {
          let dateTime = new Date()
          let startDateTime = dateTime.setDate(dateTime.getDate() - 1)
          return time.getTime() < new Date(startDateTime).getTime()
        },
        selectableRange:
          this.tf(
            new Date(new Date().setMinutes(new Date().getMinutes())),
            'HH:mm:ss'
          ) + ' - 23:59:59'
      },
      copyLoading: false,
      isSubmitting: false,
      ID: '', // 合约ID
      list: [], // 列表
      body: {},
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      // 表单配置项
      dialogValue: {
        beginTime: '',
        startTime: ''
      },
      addForm: tableObj.addForm,
      dialogTitle: '',
      dialogShow: false,
      tableObj: tableObj,
      headData: tableObj.headData,
      columns: tableObj.tableData,
      startList: [],
      stopList: [],
      myBody: {},
      timer: '', // 定时器
      triggerList: []
    }
  },
  created() {},
  mounted() {
    this.getTriggerType()
    this.getAliases()
  },
  beforeDestroy() {
    if (this.timer) {
      window.clearInterval(this.timer)
      this.timer = ''
    }
  },
  methods: {
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = []
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.addForm[0].items.shard.children = array
      }
    },
    // 定时刷新数据
    refresh(currentPage) {
      var _this = this
      window.clearInterval(this.timer)
      this.timer = window.setInterval(function () {
        _this.getData(currentPage)
      }, 5000)
    },
    // 触发开始时间并赋值
    handleTime() {
      this.$set(this.dialogValue, 'startTime', this.dialogValue.beginTime)
    },
    // 终止合约
    async handleStop(id) {
      let options = {
        taskID: id
      }
      const { code, data } = await this.$api.middleWare.taskStop(options)
      // console.log(data)
      if (code === 200) {
        this.$message.success('合约已终止')
        this.getData(1)
      } else {
        this.$message.error(data)
      }
    },
    // 重启合约
    async handleStart(id) {
      let options = {
        taskID: id
      }
      const { code, data } = await this.$api.middleWare.taskStart(options)
      // console.log(data)
      if (code === 200) {
        this.$message.success('合约已重启')
        this.getData(1)
      } else {
        this.$message.error(data)
      }
    },
    // 判断条件类型
    getTriggerTypeName(triggerID) {
      if (this.triggerList !== []) {
        for (let item of this.triggerList) {
          if (triggerID === item.value) {
            return item.label
          }
        }
      }
    },
    // 获取分类触发条件
    async getTriggerType() {
      const { data } = await this.$api.middleWare.triggerType()
      // console.log('data：', data)
      // 进行转化
      var array = []
      for (let item of data) {
        var obj = {}
        obj.value = item.id
        obj.label = item.label
        array.push(obj)
      }
      this.triggerList = array
      this.addForm[0].items.startTriggerID.children = array
      this.addForm[0].items.stopTriggerID.children = array
      // this.dialogValue.startTriggerID = array[0].id
      // this.dialogValue.stopTriggerID = array[0].id
      // console.log(this.addForm)
    },
    getTriggerArgs(id) {
      let options = {
        triggerID: id
      }
      this.$api.middleWare.triggerArgs(options).then((res) => {
        // if (res.data.length !== 0) {
        // console.log('返回:', res.data)
        return res.data
        // }
      })
    },

    // 点击取消
    cancel() {
      // console.log('取消事件')
      this.$refs.addForm.resetFields()
    },

    // 点击提交按钮,触发提交事件
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    async onSubmit(form, ref) {
      console.log(form)
      if (this.isSubmitting) {
        return
      }
      // if (form.startTriggerID !== 1 || form.stopTriggerID !== 1) {
      var start = []
      // 循环对象
      for (let i = 0; i < this.startList.length; i++) {
        let obj = {}
        obj.parentTriggerID = form.startTriggerID
        obj.triggerID = this.startList[i].id
        obj.triggerValue = form['start' + i]
        start.push(obj)
      }
      var end = []
      // 循环对象
      for (let j = 0; j < this.stopList.length; j++) {
        let obj = {}
        obj.parentTriggerID = form.stopTriggerID
        obj.triggerID = this.stopList[j].id
        obj.triggerValue = form['tt' + j]
        end.push(obj)
      }
      // console.log(start)
      // console.log(end)
      // 毫秒时间戳转换
      var startTime = new Date(form.beginTime).getTime()
      // console.log(startTime)
      // 处理外部参数字段
      var arr = []
      if (form.args) {
        arr.push(form.args)
      }
      for (var k = 10; k < 100; k++) {
        if (form['args' + k] !== '' && form['args' + k]) {
          arr.push(form['args' + k])
        }
      }
      var _arr = JSON.stringify(arr)
      // console.log({ array: _arr })
      // 添加合约监控
      let body = {
        args: _arr,
        contractID: form.contractID,
        cron: JSON.parse(form.cron),
        cycle: JSON.parse(form.cycle),
        func: form.func,
        shard: form.shard,
        startTime: startTime,
        startTriggerID: form.startTriggerID,
        stopTriggerID: form.stopTriggerID,
        start: start,
        end: end
      }
      this.isSubmitting = true
      this.$api.middleWare.taskAdd(body).then((res) => {
        this.dialogShow = false
        if (res.code === 200) {
          this.$message.success('新增成功')
          this.getData(1)
          // this.refresh(1)
        } else {
          this.$message.error(res.message)
        }
        setTimeout(() => {
          this.isSubmitting = false
        }, 1000)
      })
      // } else {
      //   this.$message.error('请至少选择一个执行条件')
      // }
    },

    // 一键复制
    copy(e, text) {
      if (this.copyLoading) {
        return
      }
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
      setTimeout(() => {
        this.copyLoading = false
      }, 1000)
    },
    // 跳转详情页
    handleDetail(value) {
      this.$router.push({
        name: 'contractTaskDetails',
        query: { taskID: value }
      })
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    dataBody() {
      let body = {
        shard: this.datas.shard,
        contractID: this.datas.contractID || null,
        status: this.datas.status
      }
      return body
    },
    async getData(currentPage, type) {
      this.loading = true
      let option = {
        page: currentPage - 1,
        size: this.pageData.size
      }
      let { data, code } = await this.$api.middleWare.taskList(
        option,
        this.dataBody()
      )
      if (code === 200) {
        // console.log('数据1:', data.content)
        this.pageData.total = data.total
        this.list = data.content
      }
      this.loading = false
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
      // this.refresh(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
      // this.refresh(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.dict-value {
  font-weight: bold;
  color: #3f536e;
  margin-bottom: 10px;
}
.table-row:hover {
  text-decoration: underline;
  color: $--color-primary;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.div-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
// js样式
/deep/ pre {
  background: rgb(230, 230, 230);
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  overflow-x: scroll;
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
}
.tableList-addForm {
  /deep/.el-dialog {
    max-width: 700px;
    .el-dialog__body {
      padding: 30px 80px 50px;
      .tableList-form-buttonBox {
        text-align: center;
      }
    }

    .el-input {
      width: 460px;
    }
  }
}
/deep/.tableList-addForm .el-dialog .el-dialog__body .tableList-form-buttonBox {
  text-align: right;
  margin-top: 16px;
}
</style>
