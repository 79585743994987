<template>
  <div class="search-form">
    <el-form ref="form"
             :model="form"
             label-position="right"
             label-width="100px">
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="合约ID">
            <el-input @keyup.enter.native="confirm"
                      class="input"
                      clearable
                      v-model="form.contractID"
                      placeholder="合约ID"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="分片名">
            <el-select class="inputWid"
                       @keyup.enter.native="confirm"
                       @change="handleSelect"
                       v-model="form.shard">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="执行状态">
            <el-select class="inputWid"
                       @keyup.enter.native="confirm"
                       v-model="form.status">
              <el-option v-for="item in statusList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:100%">
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: [],
      statusList: [
        {
          value: null,
          label: '全部'
        },
        {
          value: 0,
          label: '未执行'
        },
        {
          value: 1,
          label: '执行中'
        },
        {
          value: 2,
          label: '已结束'
        },
        {
          value: 3,
          label: '已暂停'
        },
        {
          value: 4,
          label: '正在暂停'
        },
        {
          value: 5,
          label: '重启中'
        }
      ]
    }
  },
  mounted() {
    this.getAliases()
  },
  methods: {
    // 分片名切换刷新
    handleSelect() {
      this.$emit('select', this.form.shard)
    },
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = [
          {
            value: null,
            label: '全部'
          }
        ]
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.options = array
      }
    },
    rest() {
      this.form.status = null
      this.form.shard = null
      this.form.contractID = ''
      this.confirm()
    },
    confirm() {
      let _form = {
        status: this.form.status,
        shard: this.form.shard,
        contractID: this.form.contractID ? this.trim(this.form.contractID) : ''
      }
      this.$emit('search', _form)
    },
    trim(s) {
      // 去除目标字符串左右两边的空白字符
      return s.replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}
</script>
